/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useRef, useState } from 'react';
import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../core/Auth';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { verifOtp , getUserByToken} from '../core/_requests';


export function TwoFactorSMS(this: any) { 
  
  const intl = useIntl()

  const {setTwoFacter , otp,setCurrentUser} = useAuth()
  const user = JSON.parse(localStorage.getItem('auth-user-digicap') || '{}');
  const [codeOPT, setCodeOPT] = useState('') 
  const [errorApi, setErrorApi] = useState(false)
  const [disabledBtn, setbtnDisabled] = useState(true)


  const ref1 = useRef<HTMLInputElement>(null);
  const ref2 = useRef<HTMLInputElement>(null);
  const ref3 = useRef<HTMLInputElement>(null);
  const ref4 = useRef<HTMLInputElement>(null);

  useEffect( () => {
    if(ref1 && ref1.current !== null) {
      ref1.current.focus();
    }
  },[]) 


  /*useEffect( () => {
    if(ref1 && ref1.current !== null) {
      ref1.current.focus();
    }
  },[queryFilter]) */
  

  let navigate = useNavigate(); 
  const routeChange = async () =>{ 

      
      let code=ref1.current?.value.toString()+''+ref2.current?.value.toString()+''+ref3.current?.value.toString()+''
      +ref4.current?.value.toString()

      setCodeOPT(code)

    try {
      const {data} = await verifOtp(code,user.id)
      const  dataUser= await getUserByToken(data.token)
      setCurrentUser(dataUser.data);
      localStorage.setItem("twoFactor", "true");
      localStorage.setItem("currentUser", JSON.stringify(dataUser.data));
      setTwoFacter(true);
      localStorage.setItem("token", data.token);
      navigate("/dashboard");
    } catch (error) {
      setErrorApi(true);
    }

  }

  const keyDown = (refName,previoisRef, event) => {

    if(event.target.value=='' && previoisRef && previoisRef.current !== null){
      previoisRef.current.focus();
    }else{
      if(refName && refName.current !== null) {
        const val = event.target.value;
        if(val!= '' && val>-1 && val <10){
          refName.current.focus();
        }else{
          event.target.value=null;
        }
      }
  
      if(!refName){
        const val = event.target.value;
        if(val>9){
          event.target.value=null;
        }
      }
    }

    if(ref1.current?.value && ref2.current?.value && ref3.current?.value && ref4.current?.value){
      setbtnDisabled(false)
    }else{
      setbtnDisabled(true)
    }
  }
  
  return (
    <>
      <form className='form w-100 mb-20'>
        <div  className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
          <img alt='DIGICAP' className='mb-8 logoAuthSmall' src={toAbsoluteUrl('/media/logos/digicap.svg')} />
          <h1 className="mb-8 colorTitle">{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
        </div>

        <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid '>
          
          <div className='mt-2 w-400px'>

              <h2 className="fw-bold align-items-center text-gray-900 mb-4">Vérification à deux facteurs</h2>
              <div className="text-muted fw-semibold fs-5 mb-5">Entrez le code de vérification que nous avons envoyé à</div>
              <div className="fw-bold text-gray-900 fs-3">
                {otp==='email'  ? user.email : user.mobile}
              </div>
             
              <div className="fw-bold text-center text-gray-900 fs-6 mt-10 mb-2 ms-1">Tapez votre code de sécurité à 4 chiffres</div>
              <div className="text-center mb-0 d-flex flex-center flex-row" >                
                
                <input ref={ref1} onChange={keyDown.bind(this, ref2 ,null)} type="number" step="1" min="0" max="9" autoComplete="off" className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   />
                <input ref={ref2} onChange={keyDown.bind(this, ref3 ,ref1)} type="number" step="1" min="0" max="9" autoComplete="off" className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   /> 
                <input ref={ref3} onChange={keyDown.bind(this, ref4 ,ref2)} type="number" step="1" min="0" max="9" autoComplete="off" className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   />                     
                <input ref={ref4} onChange={keyDown.bind(this, null ,ref3)} type="number" step="1" min="0" max="9" autoComplete="off" className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"   /> 
              </div>

              {errorApi ? 
              <div className='mt-3 alert alert-danger'>
                <div className='alert-text font-weight-bold'>La vérification a échoué. Merci d'essayer de nouveau</div>
              </div>
               : <></>}
              

              <button type='button' className='btn btn-primary mb-4 BtnRound py-30 px-10 mt-10' disabled={disabledBtn}
              onClick={() => routeChange()}>
                <span className='indicator-label'>
                  Valider
                </span>
              </button>

              <div className="text-center fw-semibold fs-5">
                  <span className="text-muted me-1">Vous n'avez pas reçu le code ?</span>
                  <a href="#" className="link-primary fs-5 me-1">Renvoyer</a>
              </div>

          </div>
        </div>
      </form>
    </>
    
  )
}
