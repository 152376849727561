import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const CURR_USER = `${API_URL}/users/get-current-user`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const SEND_OTP = `${API_URL}/ps/send-ps-otp`
export const VALIDATE_OTP= `${API_URL}/ps/validate-ps-otp`


// Server should return AuthModel
export function login(username: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}




export function getUserByToken(token: string) {
  return axios.get<UserModel>(CURR_USER, {
    headers: {
      Authorization : `Bearer ${token}`
    }
  })
}


export function sendOtp(via: string, id: string) {
  return axios.post<AuthModel>(SEND_OTP, {
    "send_via": via,
    "authenticatorId":id
  })
}

export function verifOtp(code: string, id: string) {
  return axios.post<AuthModel>(VALIDATE_OTP, {
    "otp_code": code,
    "authenticatorId":id
  })
}