/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const intl = useIntl()
  const {classes} = useLayout()
  return (
    <div
      id='kt_footer'
      className='footer py-4 d-flex flex-lg-column'
      style={{backgroundColor: 'white'}}
    >
      {/* begin::Container */}
      <div className={`${classes.footerContainer} d-flex flex-column  flex-stack`}>
        {/* begin::Copyright */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5 mt-4'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold  fs-base'>
            <p>Copyright © <a href='#'  target='_blank'> DigiCap </a> {new Date().getFullYear()}</p>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
