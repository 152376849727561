/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>

      <AsideMenuItem
      to='/dashboard'
      title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      fontIcon='bi bi-house'
      bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      className='py-3' />


      <AsideMenuItem
      to='/patientsnonvalide'
      title={intl.formatMessage({id: 'MENU.PATIENT.ATTENTE'})}
      fontIcon='bi-person-exclamation'
      bsTitle={intl.formatMessage({id: 'MENU.PATIENT.ATTENTE'})}
      className='py-3' />


      <AsideMenuItem
      to='/patientsvalide'
      title={intl.formatMessage({id: 'MENU.PATIENT.VALIDE'})}
      bsTitle={intl.formatMessage({id: 'MENU.PATIENT.VALIDE'})}
      fontIcon='bi-person-check'
      className='py-3' />

      {/*<AsideMenuItem
      to='/ppcp'
      title={intl.formatMessage({id: 'MENU.PPCP'})}
      bsTitle={intl.formatMessage({id: 'MENU.PPCP'})}
      fontIcon='bi-journal-medical'
  className='py-3' />*/}

    </>
  )
}
