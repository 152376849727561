/* eslint-disable jsx-a11y/anchor-is-valid */

import {useIntl} from 'react-intl'
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useState } from 'react';
import { sendOtp } from '../core/_requests';
import { useAuth } from '../core/Auth';


export function TwoFactor() {

  const {setOtp} = useAuth()


  const user = JSON.parse(localStorage.getItem('auth-user-digicap') || '{}');
  const [optionOPT, setOptionOPT] = useState('')
  const [disabledBtn, setbtnDisabled] = useState(true)

  const onOptionChange = e => {
    setOptionOPT(e.target.value);
    setbtnDisabled(false)
  }


  const intl = useIntl()
  let navigate = useNavigate(); 
  const routeChange = async (path) =>{ 

    setOtp(optionOPT);

    try {
      const {data} = await sendOtp(optionOPT,user.id)
      navigate(path);
    } catch (error) {
      console.error('Error fetching todos:', error);
    }

  }
  
  return (
    <>
      <form className='form container px-14 mb-14'>
        <div  className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid'>
          <img alt='DIGICAP' className='mb-8 logoAuthSmall' src={toAbsoluteUrl('/media/logos/digicap.svg')} />
          <h1 className="mb-8 colorTitle">{intl.formatMessage({id: 'AUTHENTICATION.Name.PAGE'})}</h1>
        </div>

        <div className='text-center mb-0 d-flex flex-center flex-column flex-lg-row-fluid '>
          
          <div className='mt-2 w-400px'>

              <h3 className="fw-bold d-flex align-items-center text-gray-900 mb-4">Confirmation d’identité à deux facteurs</h3>
              <input onChange={onOptionChange} type="radio" className="btn-check" name="auth_option" value="email"  id="two_factor_authentication_option_1" />
              <label className="btn btn-outline btn-outline-dashed px-7 py-3 d-flex align-items-center mb-4 w-100 BorderColorGray" htmlFor="two_factor_authentication_option_1">
                  <span className="d-block fw-semibold text-start">    
                      <i className="fas fa-envelope fs-2"></i>      
                      <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                          Par e-mail : {user?.email}
                      </span>
                  </span>
              </label> 

              <input onChange={onOptionChange}  type="radio" className="btn-check" name="auth_option" value="sms" id="two_factor_authentication_option_2" />
              <label className="btn btn-outline btn-outline-dashed px-7 py-3 d-flex align-items-center mb-6 w-100 BorderColorGray" htmlFor="two_factor_authentication_option_2">
                <span className="d-block fw-semibold text-start">     
                    <i className="fas fa-mobile fs-2"></i>                            
                    <span className="text-gray-900 fw-bold d-block fs-4 mb-2">
                        Par SMS : {user?.mobile}
                    </span>
                </span>
              </label>   


              <button type='button' className='btn btn-primary mb-0 BtnRound py-30 px-10' disabled={disabledBtn}
              onClick={() => routeChange('otp')}>
                <span className='indicator-label'>
                  Continuer
                </span>
              </button>
          </div>
        </div>
      </form>
    </>
    
  )
}
