import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {BuilderPage} from './BuilderPage'
import { useIntl } from 'react-intl'

const BuilderPageWrapper: FC = () => {

  const intl = useIntl()

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  
  ]
  
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>{intl.formatMessage({id: 'MENU.PPCP'})}</PageTitle>
      <BuilderPage />
    </>
  )
}

export default BuilderPageWrapper
