/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import './auth.scss';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-700px p-6'>
            <Outlet />
            <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-500px'>
              <div className='d-flex flex-stack'>
                <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                  <a href="#" target="_blank">Accueil</a>
                  <a href="#" target="_blank">Qui sommes nous ?</a>
                </div>
                <div className='d-flex fw-semibold text-primary fs-base gap-5'>
                  <a href="#" target="_blank">Contactez nous</a>
                  
                </div>
              </div>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5 my-11'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold  fs-base'>
            <p>Copyright © <a href='#'  target='_blank'> DigiCap </a> {new Date().getFullYear()}</p>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
    </div>
  )
}

export {AuthLayout}
