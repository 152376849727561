import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink} from '../../../_metronic/layout/core'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'

const DashboardPage = () => {
  let navigate = useNavigate()
  const routeChange = (path) => {
    navigate(path)
  }

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-column-fluid'>
        <div className='app-container container-fluid'>
          <div className='row  col-xl-12 '>
            <div className='col-xl-4'>
              <div className='card h-md-100 '>
                <div className='card-body d-flex flex-column'>
                  <div className='mb-2 contentBlockHome'>
                    <h1 className='fw-bolder text-gray-800 text-center lh-lg'>
                      Création d’un PPCP
                      <br />
                      <span className='fw-semibold'>
                        Accéder aux PPCP pré-renseignés par vos patients
                      </span>
                    </h1>
                  </div>

                  <div className='text-center mb-1'>
                    <span
                      onClick={() => routeChange('/patientsnonvalide')}
                      className='btn btn-sm btn-primary me-2'
                    >
                      Voir la liste
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='card h-md-100 '>
                <div className='card-body d-flex flex-column'>
                  <div className='mb-2 contentBlockHome'>
                    <h1 className='fw-bolder text-gray-800 text-center lh-lg'>
                      Suivis patients
                      <br />
                      <span className='fw-semibold'>Accéder aux suivis de vos patients</span>
                    </h1>
                  </div>

                  <div className='text-center mb-1'>
                    <span
                      onClick={() => routeChange('/patientsvalide')}
                      className='btn btn-sm btn-primary me-2'
                    >
                      Voir la liste
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4'>
              <div className='card h-md-100 '>
                <div className='card-body d-flex flex-column'>
                  <div className='mb-2 contentBlockHome'>
                    <h1 className='fw-bolder text-gray-800 text-center lh-lg'>
                      Statistiques
                      <br />
                      <span className='fw-semibold'>Accéder au tableau de bord</span>
                    </h1>
                  </div>

                  <div className='text-center mb-1'>
                    <span className='btn btn-sm btn-primary me-2'>Voir la liste</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const accountBreadCrumbs: Array<PageLink> = []
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
