import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL
export const GET_PATIENTS_URL = `${API_URL}/patients`
export const PPCP_URL = `${API_URL}/ps/get/ppcp`
export const UPDATE_PPCP_URL = `${API_URL}/ps`
export const UPLOAD_PDF_PPCP_URL = `${API_URL}/ps/upload/ppcp`

const getPatients = async (query: string) => {
  return await axios
    .get(`${GET_PATIENTS_URL}?${query}`)
    .then( response =>{
      return response.data
    })
}

const getPCPPById = (id: number)=> {
  return axios
    .get(`${PPCP_URL}/${id}`)
    .then( response =>{
      return response.data
    })
}

const updatePPCP = (docterId,ppcpId,data) => {
  return axios
    .put(`${UPDATE_PPCP_URL}/${docterId}/edit/ppcp/${ppcpId}`, data)
    .then((response) => response.data)
}

const UploadPDFPPCP = (ppcpId,base64) => {

  const FormData = require ('form-data');
  let formData = new FormData();
  formData.append('file', base64);
  return axios
    .post(`${UPLOAD_PDF_PPCP_URL}/${ppcpId}`, formData , {
      headers: {
        'Content-Type':'multipart/form-data',
        'Accept':'multipart/form-data'
      }
    } 
      
    )
    .then((response) => response.data)
}



/*const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}



const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}*/

export {getPatients,getPCPPById,updatePPCP,UploadPDFPPCP}
