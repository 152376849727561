import {useEffect, useState} from 'react'
import {FormattedDate, useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget} from './ChartsWidget'
import {Alert, ProgressBar} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import {getPatientMedicalProfileById} from './core/_requests'
import {PatientMedicalProfile, LocationState} from './core/_models'
import ImcChart from './components/ImcChart'
import BloodPressureChart from './components/BloodPressureChart'
import KidneyChart from './components/KidneyChart'
import PhysicalActivityChart from './components/PhysicalActivityChart'
import SmokingMonitoringChart from './components/SmokingMonitoringChart'
import DiabetesChart from './components/DiabetesChart'
import CholesterolChart from './components/CholesterolChart'
const ContentPage = () => {
  const location = useLocation()
  const patient = location.state as LocationState

  const [urlxml, setUrlXml] = useState('')
  const [urlPdf, setUrlPdf] = useState('')
  const [patientProfile, setPatientProfile] = useState<PatientMedicalProfile | null>(null)
  const [loading, setLoading] = useState(false)
  const fetchData = async (id) => {
    try {
      // Appel à la fonction  pour obtenir les données
      const result = await getPatientMedicalProfileById(id)
      console.log(id)
      console.log(result)

      setPatientProfile(result)

      setLoading(false) // Met fin au chargement
    } catch (error) {
      console.error('Erreur lors de la récupération des données  :', error)
      setLoading(false) // Met fin au chargement en cas d'erreur
    }
  }
  useEffect(() => {
    if (patient?.patientMedicalProfile.ppcp.ppcpFileName) {
      let data = patient.patientMedicalProfile.ppcp.ppcpFileName + ''
      let dataa = data.split('.')

      setUrlXml('https://espace-sante.impact-dev.tn/public/uploads/PPCP/XML/' + dataa[0] + '.xml')
      setUrlPdf('https://espace-sante.impact-dev.tn/public/uploads/PPCP/PDF/' + dataa[0] + '.pdf')
    }
    fetchData(patient?.patientMedicalProfile.id)
  }, [patient])

  const downloadFiles = (type) => {
    if (type === 'xml') {
      window.open(urlxml)
    }

    if (type === 'pdf') {
      window.open(urlPdf)
    }

    setTimeout(function () {}, 500)
  }

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  const listCycleVie = [
    {
      row: [
        {
          date: '03-01-2024',
          status: 'success',
          actions: ['Admission à l’hôpital'],
          actionsPerso: [
            {
              title: 'Cholestérol',
              image: 'pression-arterielle.svg',
            },
            {
              title: 'Diabète',
              image: 'diabete.svg',
            },
          ],
        },
        {
          date: '05-01-2024',
          status: 'success',
          actions: ['Intervention médicale'],
          actionsPerso: [],
        },
        {date: '15-01-2024', status: 'success', actions: ['Sortie de l’hôpital'], actionsPerso: []},
      ],
    },
    {
      row: [
        {
          date: '20-02-2024',
          status: 'success',
          actions: ['Consultation de suivi', 'Consultation de post intervention'],
          actionsPerso: [],
        },
        {
          date: '05-03-2024',
          status: 'success',
          actions: ['Consultation de post intervention'],
          actionsPerso: [],
        },
        {
          date: '12-03-2024',
          status: 'success',
          actions: ['Consultation de suivi'],
          actionsPerso: [],
        },
      ],
    },
    {
      row: [
        {
          date: '22-03-2023',
          status: 'success',
          actions: ['Consultation de suivi'],
          actionsPerso: [],
        },
        {
          date: '22-04-2024',
          status: 'success',
          actions: ['Consultation de suivi'],
          actionsPerso: [],
        },
        {
          date: '03-07-2024',
          status: 'primary',
          actions: ['Consultation de suivi'],
          actionsPerso: [],
        },
      ],
    },
  ]

  const medicament = ['Médicament 1', 'Médicament 2', 'Médicament 3']
  const dispensations = [
    {
      date: '22-04-2024',
      medicament,
    },
    {
      date: '22-03-2023',
      medicament,
    },
    {
      date: '12-03-2024',
      medicament,
    },
  ]
  const SaisiePateint = [
    {
      date: '05-03-2024',
      medicament,
    },
    {
      date: '20-02-2024',
      medicament,
    },
    {
      date: '15-01-2024',
      medicament,
    },
  ]

  /*const state = {
    options: {
      chart: {
        id: "basic-bar",
        
      },
      type: 'scatter',
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
      }
    },
    
    series: [
      {
        name: "series-1",
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }
    ]
  };*/

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='cardPatientInfo'>
          <div className='p-0 row col-md-12'>
            <div className='d-flex'>
              <div className='col-md-4 flex-column rightborder  border-primary '>
                <div className='p-4'>
                  <div className='d-flex flex-wrap flex-sm-nowrap'>
                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <span className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                              {patientProfile?.patient?.birthName}{' '}
                              {patientProfile?.patient?.firstNames}
                            </span>
                          </div>

                          <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                            <span>
                              <b>Contact</b> : {patientProfile?.patient?.user?.mobile}
                            </span>
                          </div>
                        </div>
                        <div className='d-flex flex-column'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-4 flex-column rightborder  border-primary'>
                <div className='p-4'>
                  <div className='w-100'>
                    <div className='d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2'>
                      <span>
                        <b>Diagnostic</b>
                      </span>
                    </div>

                    <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                      <span>{patient?.patientMedicalProfile.diagnostic}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-4 flex-column '>
                <div className='p-4'>
                  <div className='d-flex flex-wrap flex-sm-nowrap'>
                    <div className='flex-grow-1'>
                      <div>
                        <div className='d-flex flex-column'></div>

                        <div className='d-flex flex-column'>
                          <div className='d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2'>
                            <span>
                              <b>Date de sortie :</b> 15/01/2024
                            </span>
                          </div>

                          <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                            <span>
                              <b>Age :</b> {patient?.age}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-xxl-stretch mb-0'>
          <div className='card-header p-2'>
            <div className='card-toolbar flex-column w-100'>
              <div className='d-flex flex-column w-100'>
                <div className='fs-1 fw-bolder colorPrimary'>Documents et actes médicaux</div>
              </div>
            </div>
          </div>

          <div className='card-body p-2 row col-md-12 '>
            <div className='p-4'>
              <Alert key={'info'} variant={'info'} className='row col-md-12 m-0'>
                <div className='row col-md-12'>
                  <div className='col-md-6'>
                    <span className='py-3 d-flex'>
                      Télécharger les documents et actes médicaux présents sur le DMP
                    </span>
                  </div>
                  <div className='col-md-6 text-end iconDown'>
                    <button
                      onClick={() => downloadFiles('pdf')}
                      type='button'
                      className='btn btn-outline-primary px-6 mx-2  btn-sm'
                    >
                      <i className='bi bi-file-earmark-arrow-down fs-2 mx-2 white'></i> PDF
                    </button>

                    <button
                      onClick={() => downloadFiles('xml')}
                      type='button'
                      className='btn btn-outline-primary px-6  btn-sm'
                    >
                      <i className='bi bi-file-earmark-arrow-down fs-2 mx-2'></i> XML
                    </button>
                  </div>
                </div>
              </Alert>
            </div>
            {patientProfile?.cholesterolMonitorings &&
            patientProfile?.cholesterolMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Cholestérol</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/cholesterol.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Cholesterol'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <CholesterolChart imcData={patientProfile?.cholesterolMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {patientProfile?.bloodPressureMonitorings &&
            patientProfile?.bloodPressureMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Pression artérielle</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/pression-arterielle.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <BloodPressureChart
                      bloodPressureData={patientProfile?.bloodPressureMonitorings}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.kidneyMonitorings && patientProfile?.kidneyMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Fonction rénale</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/fonction-renale.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <KidneyChart KidneyData={patientProfile?.kidneyMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.weightMonitorings && patientProfile?.weightMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>IMC</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/poids.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <ImcChart imcData={patientProfile?.weightMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.physicalActivityMonitorings &&
            patientProfile?.physicalActivityMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Activité physique</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/activite.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <PhysicalActivityChart stepData={patientProfile?.physicalActivityMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.smokingMonitorings && patientProfile?.smokingMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Tabagisme</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/tabagisme.svg'}
                        alt='tabagisme'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='tabagisme'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <SmokingMonitoringChart tabacData={patientProfile?.smokingMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.diabetesMonitorings &&
            patientProfile?.diabetesMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Diabète</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/diabete.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    {' '}
                    <DiabetesChart diabeteData={patientProfile?.diabetesMonitorings} />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
            {patientProfile?.treatmentMonitorings &&
            patientProfile?.treatmentMonitorings.length > 0 ? (
              <div className='p-4 col-md-4'>
                <div className='border borderDigipop shadow p-4'>
                  <div className='row col-md-12'>
                    <div className='col-md-8'>
                      <h2 className='colorPrimary pt-4 m-b-5'>Traitement</h2>
                    </div>
                    <div className='col-md-4 text-end'>
                      <img
                        src={'../media/logos/traitements.svg'}
                        alt='pression-arterielle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Pression arterielle'
                      />
                    </div>
                  </div>

                  <div>
                    <div className='row col-md-12 mb-3 m-0'>
                      {/* <div className='col-md-6'>
                      <h3 className='px-2 pb-2'>Dispensations</h3>
                      <div className='accordion' id='kt_accordion_1'>
                        {dispensations.map(function (item, i) {
                          return (
                            <div className='accordion-item'>
                              <h2 className='accordion-header'>
                                <button
                                  className='accordion-button fs-4 fw-semibold'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={'#kt_accordion_' + i + '_body_' + i}
                                  aria-controls={'kt_accordion_' + i + '_body_' + i}
                                >
                                  {item.date}
                                </button>
                              </h2>
                              <div
                                id={'kt_accordion_' + i + '_body_' + i}
                                className='accordion-collapse collapse'
                                aria-labelledby={'kt_accordion_' + i + '_header_' + i}
                                data-bs-parent={'#kt_accordion_' + i}
                              >
                                <div className='accordion-body p-0'>
                                  <div className='navi navi-bold navi-hover navi-active navi-link-rounded '>
                                    <div className='navi-item mb-0'>
                                      <div className='menu menu-column '>
                                        {medicament.map(function (med, m) {
                                          return (
                                            <div className='menu-item'>
                                              <a href='#' className='menu-link'>
                                                <span className='menu-icon'>
                                                  <i className='bi bi-capsule fs-3'></i>
                                                </span>
                                                <span className='menu-title'>{med}</span>
                                              </a>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div> */}
                      <div className='col-md-6 '>
                        <h3 className='px-2 pb-2'>Saisies patient</h3>
                        <div className='accordion' id='kp_accordion_1'>
                          {patientProfile?.treatmentMonitorings.map((item, i) => (
                            <div className='accordion-item' key={i}>
                              <h2 className='accordion-header'>
                                <button
                                  className='accordion-button fs-4 fw-semibold'
                                  type='button'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#kp_accordion_${i}_body`}
                                  aria-controls={`kp_accordion_${i}_body`}
                                >
                                  <FormattedDate
                                    value={item.date}
                                    day='2-digit'
                                    month='2-digit'
                                    year='numeric'
                                  />
                                </button>
                              </h2>
                              <div
                                id={`kp_accordion_${i}_body`}
                                className='accordion-collapse collapse'
                                aria-labelledby={`kp_accordion_${i}_header`}
                                data-bs-parent={`#kp_accordion_${i}`}
                              >
                                <div className='accordion-body p-0'>
                                  <div className='navi navi-bold navi-hover navi-active navi-link-rounded'>
                                    <div className='navi-item mb-0'>
                                      <div className='menu menu-column '>
                                        {item?.medications?.map((medication, m) => (
                                          <div className='menu-item' key={m}>
                                            <a href='#' className='menu-link'>
                                              <span className='menu-icon'>
                                                <i className='bi bi-capsule fs-3'></i>
                                              </span>
                                              <span className='menu-title'>
                                                {medication.denomination}
                                              </span>
                                            </a>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className='card card-xxl-stretch mb-0'>
          <div className='card-header p-2'>
            <div className='card-toolbar flex-column w-100'>
              <div className='d-flex flex-column w-100'>
                <div className='fs-1 fw-bolder colorPrimary'>Ligne de vie</div>
              </div>
            </div>
          </div>

          <div className='card-body p-2 row position-relative'>
            <div className='text-end pb-3 w-150px'>
              <span className='badge mx-4 mb-2 badge-sm badge-primary badgeCycleVie'>2024</span>
              <ProgressBar variant='success' now={40} />
            </div>
          </div>

          <div className='card-body p-2 row position-relative'>
            <div className='dropdown text-end'>
              <button
                className='btn btn-primary btn-brand dropdown-toggle btnDropDown '
                type='button'
                id='dropdownMenuButton1'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                2024
              </button>
              <ul className='dropdown-menu text-end' aria-labelledby='dropdownMenuButton1'>
                <li className='link px-4 py-2'>2023</li>
                <li className='link px-4 py-2'>2022</li>
                <li className='link px-4 py-2'>2021</li>
                <li className='link px-4 py-2'>Tous</li>
              </ul>
            </div>

            <div className='py-11 container col-md-8 px-0'>
              {' '}
              {/*d-flex flex-column flex-center */}
              <div className='TimeLineDiGiCAP '>
                {' '}
                {/* text-center mb-11 d-flex flex-center flex-column flex-lg-row-fluid */}
                {listCycleVie.map(function (list, i) {
                  return (
                    <div className='ItemRow'>
                      {list.row.map(function (item, j) {
                        return (
                          <div className='item'>
                            {i === 1 && j === 0 ? (
                              <div className='lastItemInverse'></div>
                            ) : (
                              <span></span>
                            )}

                            <div className={'badge  badge-lg badge-' + item.status}>
                              {item.date}
                            </div>
                            <div className='actions'>
                              {item.actions.map(function (action, i) {
                                return (
                                  <span className='content'>
                                    <i className='fa fa-plus-square iconAction'></i>
                                    <span className='textAction'>{action}</span>
                                  </span>
                                )
                              })}
                              {item.actionsPerso.map(function (actionPerso, i) {
                                return (
                                  <span className='content'>
                                    <img
                                      className='imageAction'
                                      src={'../media/logos/' + actionPerso.image}
                                    ></img>
                                    <span className='textAction'>{actionPerso.title}</span>
                                  </span>
                                )
                              })}
                            </div>

                            {i === 0 && j === 2 ? <div className='lastItem'></div> : <span></span>}
                          </div>
                        )
                      })}
                    </div>

                    /*<div className=''>

                        i: {i}

                        
                      </div>*/
                  )
                })}
              </div>
            </div>

            <div className='py-11 container col-md-12 px-0'>
              <div className='text-end pb-3 w-150px'>
                <span className='badge mx-4 mb-2 badge-sm badge-primary badgeCycleVie'>
                  Evénement à venir
                </span>
                <span className='badge mx-4 badge-sm badge-success badgeCycleVie'>
                  Evénement passé
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Row  */}
    </>
  )
}

const DashboardSuiviPatient = () => {
  const intl = useIntl()
  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: intl.formatMessage({id: 'MENU.PATIENT.VALIDE'}),
      path: '/patientsvalide',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.PAGE.PATIENT.SUIVI'})}
      </PageTitle>
      <ContentPage />
    </>
  )
}

export {DashboardSuiviPatient}
