import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ListPatientNonValide from '../../components/Table/ListPatientNonValide'

const ContentPage = () => {
  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <ListPatientNonValide />
      </div>
    </>
  )
}

const DashboardPatientNonValide = () => {
  const intl = useIntl()

  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.PATIENT.ATTENTE'})}
      </PageTitle>
      <ContentPage />
    </>
  )
}

export {DashboardPatientNonValide}
