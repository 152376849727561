/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {UploadPDFPPCP, getPCPPById, updatePPCP} from '../../components/Table/_requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import * as Yup from 'yup'
import clsx from 'clsx'

import {Button, Modal, Toast, ToastContainer} from 'react-bootstrap'

interface patient {
  idPatient: number
  patientName: string
  patientLastName: string
  patientSex: string
  patientBirth: string
  patientAdmissionDate: string
  patientMobile: string
  patientAge: string
  healthcareTeam: [
    {
      firstName: string
      lastName: string
      type: string
      city: string
      mobile: string
      speciality: string
      address: string
    }
  ]
}

interface props {
  dataPatient
  dataInfoPatient: patient
}

interface PPCP {
  patient: patient
  hospitalService: string
  cardiovascularType: string
  patientDischargeDate: Date
  multipleAntithrombotics: boolean
  note: string
  necessaryMonitoring: [
    {
      name: string
      type: string
      value: string
    }
  ]
  necessaryMonitoringV2: necessaryMonitoring
}

interface necessaryMonitoring {
  TA: {
    pad: string
    pas: string
    checked: boolean
  }
  LDLC: {
    mg: string
    hdl: boolean
    checked: boolean
  }
  Diabete: {
    HbA1c: string
    checked: boolean
  }
  RENALE: {
    ceratine: string
    checked: boolean
  }
  PONDERALE: {
    poids: string
    taille: string
    checked: boolean
  }
  Tabac: {
    nombre: string
    checked: boolean
  }
}

const ContentPatient = ({dataPatient, dataInfoPatient}: props) => {
  const [IMC, SetImc] = useState(0)

  useEffect(() => {
    let taille = dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.taille').value
    let poids = dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.poids').value
    if (taille > 0 && poids > 0) {
      let TempImc = Math.ceil(poids / Math.pow(taille / 100, 2))
      if (TempImc > 35) {
        dataPatient?.setFieldValue('necessaryMonitoring.PONDERALE.checked', true)
      }
      SetImc(TempImc)
    }
  }, [
    dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.taille').value,
    dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.poids').value,
  ])
  return (
    <>
      <div className='card mb-10'>
        <div className='card-header p-2'>
          <div className='card-toolbar flex-column w-100'>
            <div className='d-flex flex-column w-100'>
              <div className='fs-1 fw-bolder colorDark'>Patient</div>
            </div>
          </div>
        </div>

        <div className='card-body p-2'>
          <div className='p-4'>
            <div className='contentBox mt-0'>
              <div className='cardPatientInfo'>
                <div className='p-0 row col-md-12'>
                  <div className='d-flex'>
                    <div className='col-md-4 flex-column rightborder  border-primary '>
                      <div className='p-4'>
                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                          <div className='flex-grow-1'>
                            <div className='d-flex justify-content-between align-items-start flex-wrap'>
                              <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                  <span className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                                    {dataInfoPatient.patientName} {dataInfoPatient.patientLastName}
                                  </span>
                                </div>

                                <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                                  <span>
                                    <b>Age :</b> {dataInfoPatient.patientAge} ans
                                  </span>
                                </div>
                              </div>
                              <div className='d-flex flex-column'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4 flex-column rightborder border-primary'>
                      <div className='p-4'>
                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                          <div className='flex-grow-1'>
                            <div>
                              <div className='d-flex flex-column'> </div>
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2'>
                                  <span>
                                    <b>Date de naissance : </b>
                                    {dataInfoPatient.patientBirth}
                                    {/*<FormattedDate
                                        value={dataInfoPatient.patientBirth}
                                        day="2-digit"
                                        month="2-digit"
                                        year="numeric"
                                      />*/}
                                  </span>
                                </div>

                                <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                                  <span>
                                    <b>Sexe à la naissance :</b> {dataInfoPatient.patientSex}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-4 flex-column '>
                      <div className='p-4'>
                        <div className='d-flex flex-wrap flex-sm-nowrap'>
                          <div className='flex-grow-1'>
                            <div>
                              <div className='d-flex flex-column'> </div>
                              <div className='d-flex flex-column'>
                                <div className='d-flex flex-wrap fw-semibold fs-4 mb-4 pe-2'>
                                  <span>
                                    <b>Date d'admission :</b>
                                    {dataInfoPatient.patientAdmissionDate}
                                    {/*<FormattedDate
                                            value={dataInfoPatient.patientAdmissionDate}
                                            day="2-digit"
                                            month="2-digit"
                                            year="numeric"
                                    />*/}
                                  </span>
                                </div>

                                <div className='d-flex flex-wrap fw-semibold fs-4 pe-2'>
                                  <span>
                                    <b>Contact</b> : {dataInfoPatient.patientMobile}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Service hospitalier</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12'>
                <div className='mb-5  col-md-4'>
                  <label className='form-label fs-6 fw-bold'>
                    De quel service hospitalier faites-vous partie ?
                  </label>
                  <select
                    name='hospitalService'
                    className={clsx(
                      'form-control form-select',
                      {
                        'is-invalid':
                          dataPatient.touched.hospitalService && dataPatient.errors.hospitalService,
                      },
                      {
                        'is-valid':
                          dataPatient.touched.hospitalService &&
                          !dataPatient.errors.hospitalService,
                      }
                    )}
                    {...dataPatient?.getFieldProps('hospitalService')}
                  >
                    <option value=''></option>
                    <option value='service1'>Service 1</option>
                    <option value='service2'>Service 2</option>
                  </select>

                  {dataPatient.touched.hospitalService && dataPatient.errors.hospitalService && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{dataPatient.errors.hospitalService}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Evènement cardio-vasculaire</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5 radioEventCardio'>
                <div className='col-md-4'>
                  <div className='colorPrimary fw-bolder fs-4'>Cardiovasculaire</div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType1'
                      value='Revascularisation coronarienne hors urgences'
                      checked={
                        dataPatient.values.cardiovascularType ===
                        'Revascularisation coronarienne hors urgences'
                          ? true
                          : false
                      }
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType1'
                    >
                      Revascularisation coronarienne hors urgences
                    </label>
                  </div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType2'
                      value='Syndrome coronarien aigu'
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                      checked={
                        dataPatient.values.cardiovascularType === 'Syndrome coronarien aigu'
                          ? true
                          : false
                      }
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType2'
                    >
                      Syndrome coronarien aigu
                    </label>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='colorPrimary fw-bolder fs-4'>Neurovasculaire</div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType3'
                      value='AVC ischémique athéromateux'
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                      checked={
                        dataPatient.values.cardiovascularType === 'AVC ischémique athéromateux'
                          ? true
                          : false
                      }
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType3'
                    >
                      AVC ischémique athéromateux
                    </label>
                  </div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType4'
                      value='AIT artéro-thrombotique'
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                      checked={
                        dataPatient.values.cardiovascularType === 'AIT artéro-thrombotique'
                          ? true
                          : false
                      }
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType4'
                    >
                      AIT artéro-thrombotique
                    </label>
                  </div>
                </div>

                <div className='col-md-4'>
                  <div className='colorPrimary fw-bolder fs-4'>Maladie artérielle périphérique</div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType5'
                      value='Revascularisation artérielle périphérique'
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                      checked={
                        dataPatient.values.cardiovascularType ===
                        'Revascularisation artérielle périphérique'
                          ? true
                          : false
                      }
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType5'
                    >
                      Revascularisation artérielle périphérique
                    </label>
                  </div>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='cardiovascularType'
                      id='cardiovascularType6'
                      value='Revascularisation carotidienne'
                      onChange={dataPatient?.getFieldProps('cardiovascularType').onChange}
                      checked={
                        dataPatient.values.cardiovascularType === 'Revascularisation carotidienne'
                          ? true
                          : false
                      }
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='cardiovascularType6'
                    >
                      Revascularisation carotidienne
                    </label>
                  </div>
                </div>

                {dataPatient.touched.cardiovascularType && dataPatient.errors.cardiovascularType && (
                  <div className='fv-plugins-message-container mt-3'>
                    <div className='fv-help-block'>
                      <span role='alert'>{dataPatient.errors.cardiovascularType}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Date de sortie</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12'>
                <div className='mb-5 col-md-4'>
                  <input
                    type='date'
                    name='dischargeDate'
                    className={clsx(
                      'form-control',
                      {
                        'is-invalid':
                          dataPatient.touched.dischargeDate && dataPatient.errors.dischargeDate,
                      },
                      {
                        'is-valid':
                          dataPatient.touched.dischargeDate && !dataPatient.errors.dischargeDate,
                      }
                    )}
                    {...dataPatient.getFieldProps('dischargeDate')}
                  ></input>

                  {dataPatient.touched.dischargeDate && dataPatient.errors.dischargeDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{dataPatient.errors.dischargeDate}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Equipe soignante</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5'>
                {dataInfoPatient?.healthcareTeam?.map((medecin, index) => (
                  <div className='col-md-2 infoDocteur' key={index}>
                    <div
                      className='card bgi-no-repeat card-xl-stretch'
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30% auto',
                        backgroundImage: `url(${toAbsoluteUrl(
                          '../media/svg/shapes/abstract-1.svg'
                        )})`,
                      }}
                    >
                      <div className='card-body'>
                        <div className='d-flex flex-stack flex-grow-1'>
                          <div className='d-flex flex-column me-2'>
                            <a href='#' className='text-gray-900 text-hover-primary fw-bold fs-3'>
                              {medecin.firstName} {medecin.lastName}
                            </a>
                            <span className='text-muted fw-semibold mt-1'>
                              {medecin.type === 'PPRF' ? 'Médecin traitant' : medecin.speciality}
                            </span>
                          </div>
                        </div>

                        <div className='mt-2 fs-6 fw-semibold'>
                          <div className='col-md-12'>
                            <span className=''>
                              <i className='bi bi-house'></i>
                            </span>{' '}
                            <span>{medecin.address}</span>
                          </div>
                          <div className='col-md-12 d-none'>
                            <span className=''>
                              <i className='bi bi-envelope'></i>
                            </span>{' '}
                            <span>emma.marchand@gmail.com</span>
                          </div>
                          {medecin.mobile ? (
                            <div className='col-md-12'>
                              <span className=''>
                                <i className='bi bi-phone'></i>
                              </span>{' '}
                              <span>{medecin.mobile}</span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Surveillances nécessaires</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_TA'
                      value='true'
                      id='surv_TA'
                      checked={
                        dataPatient.values?.necessaryMonitoring?.TA?.checked === true ? true : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.TA.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.TA.checked')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_TA'
                    >
                      TA
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6'>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        PAS
                      </label>
                      <div className='input-group wInput'>
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          disabled={
                            dataPatient?.values?.necessaryMonitoring?.TA?.checked === true
                              ? false
                              : true
                          }
                          {...dataPatient?.getFieldProps('necessaryMonitoring.TA.pas')}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    PAD
                  </label>
                  <input
                    type='text'
                    className='form-control fw-bolder float-start wInput'
                    disabled={
                      dataPatient.values?.necessaryMonitoring?.TA?.checked === true ? false : true
                    }
                    {...dataPatient?.getFieldProps('necessaryMonitoring.TA.pad')}
                  ></input>
                </div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_ldlc'
                      id='surv_ldlc'
                      checked={
                        dataPatient?.values?.necessaryMonitoring?.LDLC?.checked === true
                          ? true
                          : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.LDLC.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.LDLC.checked')}
                    />

                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_ldlc'
                    >
                      LDLc {'>'} objectif
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6'>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        LDLc
                      </label>
                      <div className='input-group wInput'>
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          disabled={
                            dataPatient?.values?.necessaryMonitoring?.LDLC?.checked === true
                              ? false
                              : true
                          }
                          {...dataPatient?.getFieldProps('necessaryMonitoring.LDLC.mg')}
                        ></input>
                        <span className='input-group-text'>mg/l</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3 mt-4 '>
                  <div className='form-check form-check-custom  form-check-sm'>
                    <input
                      className='form-check-input form-check-sm'
                      type='checkbox'
                      name='surv_hdl'
                      id='surv_hdl'
                      disabled={
                        dataPatient?.values?.necessaryMonitoring?.LDLC?.checked === true
                          ? false
                          : true
                      }
                      {...dataPatient?.getFieldProps('necessaryMonitoring.LDLC.hdl')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold mx-3'
                      htmlFor='surv_hdl'
                    >
                      Ldl non calculable
                    </label>
                  </div>
                </div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_diabete'
                      id='surv_diabete'
                      checked={
                        dataPatient?.values?.necessaryMonitoring?.Diabete?.checked === true
                          ? true
                          : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.Diabete.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.Diabete.checked')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_diabete'
                    >
                      Diabète
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6 '>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        HbA1c
                      </label>
                      <div className='input-group wInput'>
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          disabled={
                            dataPatient?.values?.necessaryMonitoring?.Diabete?.checked === true
                              ? false
                              : true
                          }
                          {...dataPatient?.getFieldProps('necessaryMonitoring.Diabete.HbA1c')}
                        ></input>
                        <span className='input-group-text'>%</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'></div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_renale'
                      id='surv_renale'
                      checked={
                        dataPatient?.values?.necessaryMonitoring?.RENALE?.checked === true
                          ? true
                          : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.RENALE.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.RENALE.checked')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_renale'
                    >
                      Fonction rénale
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6'>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        Créatine
                      </label>
                      <div className='input-group wInput'>
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          {...dataPatient?.getFieldProps('necessaryMonitoring.RENALE.ceratine')}
                          disabled={
                            dataPatient?.values?.necessaryMonitoring?.RENALE?.checked === true
                              ? false
                              : true
                          }
                        ></input>
                        <span className='input-group-text'>µmol/L</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'></div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_ponderale'
                      id='surv_ponderale'
                      checked={
                        dataPatient?.values?.necessaryMonitoring?.PONDERALE?.checked === true
                          ? true
                          : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.PONDERALE.checked')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_ponderale'
                    >
                      Surcharge pondérale
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6'>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        Poids
                      </label>
                      <div className='input-group wInput'>
                        {/*disabled={dataPatient?.values?.necessaryMonitoring?.PONDERALE?.checked === true ? false : true}*/}
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          {...dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.poids')}
                        ></input>
                        <span className='input-group-text'>kg</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    Taille
                  </label>
                  <div className='input-group wInputSmall float-left'>
                    {/* disabled={dataPatient?.values?.necessaryMonitoring?.PONDERALE?.checked === true ? false : true} */}
                    <input
                      type='text'
                      className='form-control fw-bolder float-start'
                      {...dataPatient?.getFieldProps('necessaryMonitoring.PONDERALE.taille')}
                    ></input>
                    <span className='input-group-text'>cm</span>
                  </div>
                  <div className='IMCFnct'>
                    {IMC > 35 && IMC ? (
                      <span className='badge badge-warning'>IMC : {IMC}</span>
                    ) : (
                      <span className='badge badge-success'>IMC : {IMC}</span>
                    )}
                  </div>
                </div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='surv_tabac'
                      id='surv_tabac'
                      checked={
                        dataPatient?.values?.necessaryMonitoring?.Tabac?.checked === true
                          ? true
                          : false
                      }
                      onChange={
                        dataPatient?.getFieldProps('necessaryMonitoring.Tabac.checked').onChange
                      }
                      {...dataPatient.getFieldProps('necessaryMonitoring.Tabac.checked')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='surv_tabac'
                    >
                      Tabac
                    </label>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='row col-md-12'>
                    <label className='form-check-label colorSecondary fs-6 fw-bold col-md-6 pt-4'>
                      Dernière mesure de référence
                    </label>
                    <div className='col-md-6'>
                      <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                        Nombre
                      </label>
                      <div className='input-group wInput'>
                        <input
                          type='text'
                          className='form-control fw-bolder float-start'
                          disabled={
                            dataPatient?.values?.necessaryMonitoring?.Tabac?.checked === true
                              ? false
                              : true
                          }
                          {...dataPatient?.getFieldProps('necessaryMonitoring.Tabac.nombre')}
                        ></input>
                        <span className='input-group-text'>/jour</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-3'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ContentObjectifs = ({dataPatient}: props) => {
  return (
    <>
      <div className='card mb-10'>
        <div className='card-header p-2'>
          <div className='card-toolbar flex-column w-100'>
            <div className='d-flex flex-column w-100'>
              <div className='fs-1 fw-bolder colorDark'>Objectifs thérapeutiques personnalisés</div>
            </div>
          </div>
        </div>

        <div className='card-body p-2'>
          <div className='p-4'>
            {/*<h2 className='colorPrimary  m-b-5'>xxx</h2>*/}
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      TA
                    </label>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    PAD {'<'}
                  </label>
                  <input
                    type='text'
                    className='form-control fw-bolder float-start wInput'
                    disabled={
                      dataPatient?.values?.necessaryMonitoring?.TA?.checked === true ? false : true
                    }
                    {...dataPatient?.getFieldProps('personalizedTreatment.TA.pad')}
                  ></input>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    PAS {'<'}
                  </label>
                  <input
                    type='text'
                    className='form-control fw-bolder float-start wInput'
                    disabled={
                      dataPatient?.values?.necessaryMonitoring?.TA?.checked === true ? false : true
                    }
                    {...dataPatient?.getFieldProps('personalizedTreatment.TA.pas')}
                  ></input>
                </div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      LDL
                    </label>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    LDLc {'<'}
                  </label>

                  <div className='input-group wInput'>
                    <input
                      type='text'
                      className='form-control fw-bolder float-start'
                      disabled={
                        dataPatient?.values?.necessaryMonitoring?.LDLC?.checked === true
                          ? false
                          : true
                      }
                      {...dataPatient?.getFieldProps('personalizedTreatment.LDL.gl')}
                    ></input>
                    <span className='input-group-text'>g/l</span>
                  </div>
                </div>

                <div className='col-md-3'></div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      Diabéte
                    </label>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    HbA1c {'<'}
                  </label>

                  <div className='input-group wInput'>
                    <input
                      type='text'
                      className='form-control fw-bolder float-start'
                      {...dataPatient?.getFieldProps('personalizedTreatment.Diabete.HbA1c')}
                      disabled={
                        dataPatient?.values?.necessaryMonitoring?.Diabete?.checked === true
                          ? false
                          : true
                      }
                    ></input>
                    <span className='input-group-text'>%</span>
                  </div>
                </div>

                <div className='col-md-3'></div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      Insuffisance rénale
                    </label>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    Créatine
                  </label>
                  <input
                    type='text'
                    className='form-control fw-bolder float-start wInput'
                    {...dataPatient?.getFieldProps('personalizedTreatment.RENALE.ceratine')}
                    disabled={
                      dataPatient?.values?.necessaryMonitoring?.RENALE?.checked === true
                        ? false
                        : true
                    }
                  ></input>
                </div>

                <div className='col-md-3'></div>
              </div>

              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      Tabac
                    </label>
                  </div>
                </div>

                <div className='col-md-3'>
                  <label className='form-check-label colorSecondary fs-6 fw-bold float-start pt-4 wLabel'>
                    Nombre
                  </label>
                  <input
                    type='text'
                    className='form-control fw-bolder float-start wInput'
                    disabled={
                      dataPatient?.values?.necessaryMonitoring?.Tabac?.checked === true
                        ? false
                        : true
                    }
                    {...dataPatient?.getFieldProps('personalizedTreatment.Tabac.nombre')}
                  ></input>
                </div>

                <div className='col-md-3'></div>
              </div>
            </div>
          </div>
          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Traitement médicamenteux à la sortie</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-md-3'>
                  <div className='form-check form-check-custom  form-check-sm w-100 mt-4'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='true'
                      checked={dataPatient.values.multipleAntithrombotics === true ? true : false}
                      {...dataPatient.getFieldProps('multipleAntithrombotics')}
                    />
                    <label
                      className='form-check-label colorSecondary fs-6 fw-bold'
                      htmlFor='flexCheckDefault'
                    >
                      Antithrombotiques multiples
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const ContentSuivis = ({dataPatient}: props) => {
  return (
    <>
      <div className='card  mb-10'>
        <div className='card-header p-2'>
          <div className='card-toolbar flex-column w-100'>
            <div className='d-flex flex-column w-100'>
              <div className='fs-1 fw-bolder colorDark'>Suivis particuliers</div>
            </div>
          </div>
        </div>

        <div className='card-body p-2'>
          <div className='p-4'>
            <h2 className='colorPrimary  m-b-5'>Note</h2>
            <div className='contentBox mt-5'>
              <div className='contentFilter row col-md-12 mb-5 d-flex flex-row'>
                <div className='col-mdd-12'>
                  <textarea
                    {...dataPatient?.getFieldProps('note')}
                    className='form-control mb-3'
                    rows={4}
                    data-kt-element='input'
                    placeholder='Saisissez une note'
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const BuilderPPCP = () => {
  const {id: idPatient} = useParams()
  let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
  const doctor = currentUser.data.doctor
  const reportTemplateRef = useRef<HTMLInputElement>(null)

  const handleGeneratePdf = async () => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'pt',
    })

    // Adding the fonts.
    doc.setFont('Inter-Regular', 'normal')

    if (reportTemplateRef.current) {
      const canvas = await html2canvas(reportTemplateRef.current)
      const imgData = canvas.toDataURL('image/png')

      const pdf = new jsPDF({
        format: 'a4',
        unit: 'pt',
      })
      const imgProps = pdf.getImageProperties(imgData)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

      pdf.addImage(imgData, 'JPG', 0, 0, pdfWidth, pdfHeight)

      let uploadPDF = await UploadPDFPPCP(idPatient, pdf.output('blob'))
    }
  }

  const [dataApiPatient, setApiPatient] = useState<PPCP[]>([])

  const fetchData = async (idPatient) => {
    let dataPPCP = await getPCPPById(parseInt(idPatient))

    setApiPatient(dataPPCP.data)
  }

  const sendData = async (data) => {
    let dataPPCP = await updatePPCP(doctor.id, idPatient, data)

    fetchData(idPatient)

    if (data['isDraft'] === true) {
      setShowToast(true)
    } else {
      setShowModal(true)
      handleGeneratePdf()
    }
  }

  useEffect(() => {
    let dateFinal = ''
    if (dataApiPatient['patientDischargeDate'] !== '') {
      let date = dataApiPatient['patientDischargeDate']?.split('/')
      if (date && date.length > 0) {
        dateFinal = date[2] + '-' + date[1] + '-' + date[0]
      }
    } else {
      let dateNow = new Date()
      let month = dateNow.getMonth() + 1
      let monthString = '' + month
      if (month < 10) {
        monthString = '0' + month
      }
      dateFinal = dateNow.getFullYear() + '-' + monthString + '-' + dateNow.getDate()
    }

    if (dataApiPatient['cardiovascularType'] === ' ') {
      dataApiPatient['cardiovascularType'] = ''
    }

    const initialValues = {
      hospitalService: dataApiPatient['patientHospitalService']
        ? dataApiPatient['patientHospitalService']
        : '',
      cardiovascularType: dataApiPatient['cardiovascularType']
        ? dataApiPatient['cardiovascularType']
        : '',
      dischargeDate: dateFinal,
      multipleAntithrombotics: dataApiPatient['multipleAntithrombotics']
        ? dataApiPatient['multipleAntithrombotics']
        : false,
      note: dataApiPatient['note'] ? dataApiPatient['note'] : '',
      necessaryMonitoring: {},
      personalizedTreatment: {},
      validationDate: null,
    }

    initialValues.necessaryMonitoring = {
      TA: {
        checked: true,
        pad: '',
        pas: '',
      },
      LDLC: {
        checked: true,
        mg: '',
        hdl: false,
      },
      Diabete: {
        checked: false,
        HbA1c: '',
      },
      RENALE: {
        checked: false,
        ceratine: '',
      },
      PONDERALE: {
        checked: false,
        poids: '',
        taille: '',
      },
      Tabac: {
        checked: false,
        nombre: '',
      },
    }

    initialValues.personalizedTreatment = {
      TA: {
        pad: '85',
        pas: '135',
      },
      LDL: {
        gl: '0.7',
      },
      Diabete: {
        HbA1c: '7',
      },
      RENALE: {
        ceratine: '',
      },
      Tabac: {
        nombre: '0',
      },
    }

    if (dataApiPatient['necessaryMonitoring']) {
      const {data} = {
        data: [dataApiPatient['necessaryMonitoring']],
      }

      //TA
      const findSurvTA = data[0].filter((entry) => entry.type === 'TA')
      if (findSurvTA.length > 0) {
        initialValues.necessaryMonitoring['TA'].checked = true
        initialValues.necessaryMonitoring['TA'].pad = findSurvTA.filter(
          (item) => item.name === 'PAD'
        )[0]?.value
        initialValues.necessaryMonitoring['TA'].pas = findSurvTA.filter(
          (item) => item.name === 'PAS'
        )[0]?.value
      }

      //LDLC
      const findSurLDLC = data[0].filter((entry) => entry.type === 'LDLC')
      if (findSurLDLC.length > 0) {
        initialValues.necessaryMonitoring['LDLC'].checked = true
        initialValues.necessaryMonitoring['LDLC'].mg = findSurLDLC.filter(
          (item) => item.name === 'mg'
        )[0]?.value
        initialValues.necessaryMonitoring['LDLC'].hdl = findSurLDLC.filter(
          (item) => item.name === 'hdl'
        )[0]?.value
      }

      //Diabete
      const findSurDiabete = data[0].filter((entry) => entry.type === 'Diabete')
      if (findSurDiabete.length > 0) {
        initialValues.necessaryMonitoring['Diabete'].checked = true
        initialValues.necessaryMonitoring['Diabete'].HbA1c = findSurDiabete.filter(
          (item) => item.name === 'HbA1c'
        )[0]?.value
      }

      //RENALE
      const findSurRENALE = data[0].filter((entry) => entry.type === 'RENALE')
      if (findSurRENALE.length > 0) {
        initialValues.necessaryMonitoring['RENALE'].checked = true
        initialValues.necessaryMonitoring['RENALE'].ceratine = findSurRENALE.filter(
          (item) => item.name === 'ceratine'
        )[0]?.value
      }

      //PONDERALE
      const findSurvPond = data[0].filter((entry) => entry.type === 'Surcharge pondérale')
      if (findSurvPond.length > 0) {
        //initialValues.necessaryMonitoring['PONDERALE'].checked=true
        initialValues.necessaryMonitoring['PONDERALE'].poids = findSurvPond.filter(
          (item) => item.name === 'poids'
        )[0]?.value
        initialValues.necessaryMonitoring['PONDERALE'].taille = findSurvPond.filter(
          (item) => item.name === 'taille'
        )[0]?.value
      }

      //Tabac
      const findTabac = data[0].filter((entry) => entry.type === 'Tabac')
      if (findTabac.length > 0) {
        initialValues.necessaryMonitoring['Tabac'].checked = true
        initialValues.necessaryMonitoring['Tabac'].nombre = findTabac.filter(
          (item) => item.name === 'nombre'
        )[0].value
      }
    }

    if (dataApiPatient['personalizedTreatment']) {
      const {data} = {
        data: [dataApiPatient['personalizedTreatment']],
      }

      //TA
      const findSurvTA = data[0].filter((entry) => entry.type === 'TA')
      if (findSurvTA.length > 0) {
        initialValues.personalizedTreatment['TA'].pad = findSurvTA.filter(
          (item) => item.name === 'PAD'
        )[0]?.value
        initialValues.personalizedTreatment['TA'].pas = findSurvTA.filter(
          (item) => item.name === 'PAS'
        )[0]?.value
      }

      //LDL
      const findSurLDLC = data[0].filter((entry) => entry.type === 'LDL')
      if (findSurLDLC.length > 0) {
        initialValues.personalizedTreatment['LDL'].gl = findSurLDLC.filter(
          (item) => item.name === 'gl'
        )[0]?.value
      }

      //Diabete
      const findSurDiabete = data[0].filter((entry) => entry.type === 'Diabete')
      if (findSurDiabete.length > 0) {
        initialValues.personalizedTreatment['Diabete'].HbA1c = findSurDiabete.filter(
          (item) => item.name === 'HbA1c'
        )[0]?.value
      }

      //RENALE
      const findSurRENALE = data[0].filter((entry) => entry.type === 'RENALE')
      if (findSurRENALE.length > 0) {
        initialValues.personalizedTreatment['RENALE'].ceratine = findSurRENALE.filter(
          (item) => item.name === 'ceratine'
        )[0]?.value
      }

      //Tabac
      const findTabac = data[0].filter((entry) => entry.type === 'Tabac')
      if (findTabac.length > 0) {
        initialValues.personalizedTreatment['Tabac'].nombre = findTabac.filter(
          (item) => item.name === 'nombre'
        )[0].value
      }
    }

    formDataPatient.setValues(initialValues)
  }, [dataApiPatient])

  useEffect(() => {
    if (idPatient) {
      fetchData(idPatient)
    }
  }, [idPatient])

  const patientData = {
    idPatient: 1,
    patientName: dataApiPatient['patientName'],
    patientLastName: dataApiPatient['patientLastName'],
    patientSex: dataApiPatient['patientSex'],
    patientBirth: dataApiPatient['patientBirth'],
    patientAdmissionDate: dataApiPatient['patientAdmissionDate'],
    patientMobile: dataApiPatient['patientMobile'],
    patientAge: dataApiPatient['patientAge'],
    healthcareTeam: dataApiPatient['healthcareTeam'],
  }

  const handleSave = (values) => {
    values['isDraft'] = true
    values['personalizedTreatment'] = organisePersonalizedTreatment(
      values.necessaryMonitoring,
      values.personalizedTreatment
    )
    values['necessaryMonitoring'] = organiseNecessaryMonitoring(values.necessaryMonitoring)

    sendData(values)
  }

  const handleValidate = () => {
    setShowModalValide(false)
    sendData(formDataPatient.values)
  }

  const organisePersonalizedTreatment = (valuesN, valuesP) => {
    let list = [{}]
    let data
    if (valuesN?.TA?.checked) {
      data = {
        type: 'TA',
        name: 'PAD',
        value: valuesP.TA.pad,
      }
      list.push({...data})

      data = {
        type: 'TA',
        name: 'PAS',
        value: valuesP.TA.pas,
      }
      list.push({...data})
    }

    if (valuesN?.LDLC?.checked) {
      data = {
        type: 'LDL',
        name: 'gl',
        value: valuesP.LDL.gl,
      }
      list.push({...data})
    }

    if (valuesN?.Diabete?.checked) {
      data = {
        type: 'Diabete',
        name: 'HbA1c',
        value: valuesP.Diabete.HbA1c,
      }
      list.push({...data})
    }

    if (valuesN?.RENALE?.checked) {
      data = {
        type: 'RENALE',
        name: 'ceratine',
        value: valuesP.RENALE.ceratine,
      }
      list.push({...data})
    }

    if (valuesN?.Tabac?.checked) {
      data = {
        type: 'Tabac',
        name: 'nombre',
        value: valuesP.Tabac.nombre,
      }
      list.push({...data})
    }

    list.splice(0, 1)
    return list
  }

  const organiseNecessaryMonitoring = (values) => {
    let list = [{}]
    let data
    if (values?.TA?.checked) {
      data = {
        type: 'TA',
        name: 'PAD',
        value: values.TA.pad,
      }
      list.push({...data})

      data = {
        type: 'TA',
        name: 'PAS',
        value: values.TA.pas,
      }
      list.push({...data})
    }

    if (values?.LDLC?.checked) {
      data = {
        type: 'LDLC',
        name: 'mg',
        value: values.LDLC.mg,
      }
      list.push({...data})

      data = {
        type: 'LDLC',
        name: 'hdl',
        value: values.LDLC.hdl,
      }
      list.push({...data})
    }

    if (values?.Diabete?.checked) {
      data = {
        type: 'Diabete',
        name: 'HbA1c',
        value: values.Diabete.HbA1c,
      }
      list.push({...data})
    }

    if (values?.RENALE?.checked) {
      data = {
        type: 'RENALE',
        name: 'ceratine',
        value: values.RENALE.ceratine,
      }
      list.push({...data})
    }

    if (values?.PONDERALE?.checked) {
      data = {
        type: 'Surcharge pondérale',
        name: 'poids',
        value: values.PONDERALE.poids,
      }
      list.push({...data})

      data = {
        type: 'Surcharge pondérale',
        name: 'taille',
        value: values.PONDERALE.taille,
      }
      list.push({...data})
    }

    if (values?.Tabac?.checked) {
      data = {
        type: 'Tabac',
        name: 'nombre',
        value: values.Tabac.nombre,
      }
      list.push({...data})
    }

    list.splice(0, 1)
    return list
  }

  const PPCPSchema = Yup.object().shape({
    hospitalService: Yup.string().required('Le service est requis'),
    cardiovascularType: Yup.string().required("L'evènement cardio-vasculaire est requis"),
    dischargeDate: Yup.string().required('Date de sortie est requis'),
  })

  const formDataPatient = useFormik({
    initialValues: {},
    validationSchema: PPCPSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      values['isDraft'] = false
      values['validationDate'] = new Date()
      values['personalizedTreatment'] = organisePersonalizedTreatment(
        values['necessaryMonitoring'],
        values['personalizedTreatment']
      )
      values['necessaryMonitoring'] = organiseNecessaryMonitoring(values['necessaryMonitoring'])
      setShowModalValide(true)

      try {
      } catch (error) {
        console.log(error)
      }
    },
  })

  useEffect(() => {
    let cardiovascularType = formDataPatient?.getFieldProps('cardiovascularType').value
    if (
      cardiovascularType === 'Revascularisation coronarienne hors urgences' ||
      cardiovascularType === 'Syndrome coronarien aigu' ||
      cardiovascularType === 'Revascularisation artérielle périphérique' ||
      cardiovascularType === 'Revascularisation carotidienne'
    ) {
      formDataPatient.setFieldValue('personalizedTreatment.LDL.gl', 0.55)
    }

    if (
      cardiovascularType === 'AVC ischémique athéromateux' ||
      cardiovascularType === 'AIT artéro-thrombotique'
    ) {
      formDataPatient.setFieldValue('personalizedTreatment.LDL.gl', 0.7)
    }
     
  
  }, [formDataPatient?.getFieldProps('cardiovascularType').value])

  const [showToast, setShowToast] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalValide, setShowModalValide] = useState(false)

  let navigate = useNavigate()
  const routeChange = (path) => {
    navigate(path)
  }

  return (
    <>
      <form onSubmit={formDataPatient.handleSubmit}>
        <div ref={reportTemplateRef}>
          <ContentPatient dataPatient={formDataPatient} dataInfoPatient={patientData} />
          <ContentObjectifs dataPatient={formDataPatient} dataInfoPatient={patientData} />
          <ContentSuivis dataPatient={formDataPatient} dataInfoPatient={patientData} />
        </div>

        <div className='card  mb-10'>
          <div className='card-footer py-6'>
            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-7'>
                <button
                  type='button'
                  className='btn btn-active-light btn-save btn-color-muted me-2'
                  onClick={() => handleSave(formDataPatient.values)}
                >
                  <span className='indicator-label'>Enregistrer ( brouillon ) </span>
                </button>

                <button type='submit' className='btn btn-primary me-2'>
                  <span className='indicator-label'>Valider</span>
                </button>
              </div>
            </div>

            <ToastContainer className='p-3' position={'bottom-end'} style={{zIndex: 1}}>
              <Toast show={showToast} bg={'success'} autohide onClose={() => setShowToast(false)}>
                <Toast.Body>PPCP a été bien enregistré</Toast.Body>
              </Toast>
            </ToastContainer>

            <Modal show={showModal} onHide={() => routeChange('/patientsnonvalide')}>
              <Modal.Header closeButton>
                <Modal.Title>PPCP Envoyé</Modal.Title>
              </Modal.Header>
              <Modal.Body>PPCP a été envoyé avec succès</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => routeChange('/patientsnonvalide')}>
                  Fermer
                </Button>
                <Button variant='primary' onClick={() => routeChange('/patientsvalide')}>
                  Voir la liste des patients en cours de suivi
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModalValide} onHide={() => setShowModalValide(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Validation PPCP</Modal.Title>
              </Modal.Header>
              <Modal.Body>Après validation le document ne sera plus modifiable !</Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowModalValide(false)}>
                  Annuler
                </Button>
                <Button variant='primary' onClick={() => handleValidate()}>
                  Valider
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </form>
    </>
  )
}

export {BuilderPPCP}
