import * as React from 'react'

import {getPatients} from './_requests'
import {FormattedDate, useIntl} from 'react-intl'
import {useEffect, useState} from 'react'

import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import {CircularProgress, Pagination, TableFooter, TableRow, TableSortLabel} from '@mui/material'
import {differenceInYears} from 'date-fns/differenceInYears'
import {format} from 'date-fns'
import {useNavigate} from 'react-router-dom'

interface dataPatient {
  birthDate: string
  birthName: String
  birthPlace: String
  firstNames: String
  id: number
  sex: String
  age: string
  patientMedicalProfile: {
    hospitalAdmissionDate: string
    ppcp: {
      id: number
    }
  }
}

export default function ListPatientNonValide() {
  const intl = useIntl()

  let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
  let doctorHospitals = currentUser.data.doctor.doctorHospitals
  let listIdHopital = [null]

  doctorHospitals.forEach((item) => {
    if (listIdHopital[0] == null) {
      listIdHopital = [item.hospital.id]
    } else {
      listIdHopital.push(item.hospital.id)
    }
  })

  const initFilter =
    '&patientMedicalProfile.ppcp.status=false&hospital_ids=' + listIdHopital.toString()
  const [dataPatient, setDataPatient] = useState<dataPatient[]>([])
  const [order, setOrder] = useState('asc')
  const [queryFilter, setQuertyFilter] = useState(initFilter)
  const [page, setPage] = useState(1)
  const [metaPage, setMetaPage] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const fetchData = async (page, filter) => {
    try {
      // Appel à la fonction  pour obtenir les données
      const result = await getPatients('page=' + page + filter)

      console.log(result)

      setMetaPage(result.meta)
      setDataPatient(result.data)
      setLoading(false) // Met fin au chargement
    } catch (error) {
      console.error('Erreur lors de la récupération des données de la zone du corps :', error)
      setLoading(false) // Met fin au chargement en cas d'erreur
    }
  }
  const handlePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // Additional logic to update data based on pageNumber, e.g., fetching data for the new page
  }
  const sortList = (field) => {
    if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrder('asc')
    }

    let filter = queryFilter + '&' + field + '=' + order

    fetchData(page, filter)
  }

  const filter = (event) => {
    setQuertyFilter(initFilter)
    let filter = initFilter
    event.preventDefault()

    if (event.target.birthName.value) {
      filter = filter + '&birthName=' + event.target.birthName.value
    }

    if (event.target.firstNames.value) {
      filter = filter + '&firstNames=' + event.target.firstNames.value
    }

    if (event.target.sex.value) {
      filter = filter + '&sex=' + event.target.sex.value
    }

    if (event.target.admission.value) {
      filter = filter + '&hospitalAdmissionDate=' + event.target.admission.value
    }

    if (event.target.birthDate.value) {
      filter = filter + '&birthDate=' + event.target.birthDate.value
    }

    if (event.target.age.value) {
      let age = parseInt(event.target.age.value)
      filter = filter + '&age=' + age
    }

    setQuertyFilter(filter)
  }

  const resetFilter = (e) => {
    e.preventDefault()
    e.target.reset()
    e.target.birthName.value = ''
    e.target.firstNames.value = ''
    e.target.sex.value = ''
    e.target.admission.value = ''
    e.target.birthDate.value = ''
    e.target.age.value = ''

    fetchData(1, initFilter)
  }

  let navigate = useNavigate()
  const routeChange = (path) => {
    navigate(path)
  }

  useEffect(() => {
    fetchData(page, queryFilter)
  }, [queryFilter,page])

  return (
    <>
      {/*** Filter  */}
      <div className='card p-0  card-xxl-stretch mb-0'>
        <div className='card-header  p-2'>
          <div className='card-toolbar flex-column w-100 m-0'>
            <div className='d-flex flex-column w-100 m-0 p-0'>
              <div className='p-1'>
                <div className='fs-1 fw-bolder colorPrimary'>Filtre</div>
              </div>
              <div className='separator border-gray-200'></div>
              <form onSubmit={filter} onReset={resetFilter}>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='contentFilter row col-md-12'>
                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Nom</label>
                      <input
                        name='birthName'
                        type='text'
                        className='form-control fw-bolder'
                      ></input>
                    </div>

                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Prénom</label>
                      <input name='firstNames' type='text' className='form-control'></input>
                    </div>

                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Date de naissance</label>
                      <input name='birthDate' type='date' className='form-control'></input>
                    </div>

                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Sexe à la naissance</label>
                      <select
                        className='form-select'
                        name='sex'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        data-kt-user-table-filter='sexe'
                        data-hide-search='true'
                      >
                        <option value=''></option>
                        <option value='homme'>Homme</option>
                        <option value='femme'>Femme</option>
                      </select>
                    </div>

                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Age</label>
                      <input name='age' type='text' className='form-control'></input>
                    </div>

                    <div className='mb-5 col-md-3 col-lg-2'>
                      <label className='form-label fs-6 fw-bold'>Date d'admission</label>
                      <input name='admission' type='date' className='form-control'></input>
                    </div>
                  </div>
                  <div className='row col-md-12'>
                    <div className='offset-md-8 col-md-4 text-end gap-1'>
                      <button
                        type='reset'
                        className='btn btn-light btn-active-light-primary mx-2 col-md-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='reset'
                      >
                        Réinitialiser
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary fw-bold col-md-5'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='filter'
                      >
                        Appliquer
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*** Table  */}
      <div className='p-0 mb-0 card card-xxl-stretch mb-5'>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column w-100'>
            <span className='card-label fw-bold text-gray-800'>
              {intl.formatMessage({id: 'MENU.PATIENT.ATTENTE'})}
            </span>
            <span className='text-gray-500 mt-1 fw-semibold fs-6 '>
              Affichage {dataPatient.length} sur {metaPage?.totalItems} patients
            </span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <div className='table-responsive'>
            <div className='table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell variant='head' className='fw-bold text-muted min-w-150px ps-0'>
                        <TableSortLabel
                          direction={order === 'asc' ? order : 'desc'}
                          onClick={() => sortList('order[birthName]')}
                        >
                          <span>Patients</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant='head' className='fw-bold text-muted min-w-140px'>
                        Sexe à la naissance
                      </TableCell>
                      <TableCell variant='head' className='fw-bold text-muted min-w-120px'>
                        Age
                      </TableCell>
                      <TableCell variant='head' className='fw-bold text-muted min-w-150px'>
                        <TableSortLabel
                          direction={order === 'asc' ? order : 'desc'}
                          onClick={() =>
                            sortList('order[patientMedicalProfile.hospitalAdmissionDate]')
                          }
                        >
                          <span>Date d'admission</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell variant='head' className='min-w-100px text-end'></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {loading ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                              <CircularProgress />
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {dataPatient.length == 0 ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                Aucun patient correspondants trouvés
                              </div>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {dataPatient.length > 0 &&
                              dataPatient.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell className='ps-0'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <a className='text-dark fw-bold text-hover-primary fs-6'>
                                          {item.birthName} {item.firstNames}
                                        </a>
                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                          <FormattedDate
                                            value={item.birthDate}
                                            day='2-digit'
                                            month='2-digit'
                                            year='numeric'
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                      {item.sex}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                      {item.age}
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                      <FormattedDate
                                        value={item?.patientMedicalProfile?.hospitalAdmissionDate}
                                        day='2-digit'
                                        month='2-digit'
                                        year='numeric'
                                      />
                                    </span>
                                  </TableCell>
                                  <TableCell>
                                    <button
                                      onClick={() =>
                                        routeChange('/ppcp/' + item.patientMedicalProfile.ppcp.id)
                                      }
                                      type='submit'
                                      className='btn btn-outline-primary px-6 d-flex justify-content-center btn-sm'
                                    >
                                      A compléter
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
                <div className=' mt-5 '>
                  <Pagination
                    color='primary'
                    showFirstButton
                    showLastButton
                    count={Math.ceil(metaPage?.totalItems / metaPage?.itemsPerPage)}
                    page={page}
                    onChange={handlePaginate}
                  />
                </div>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
