import axios from 'axios'
import {PatientMedicalProfile} from './_models'
const API_URL = process.env.REACT_APP_API_URL
export const Patient_Medical_Profile = `${API_URL}/patient_medical_profiles`

export const getPatientMedicalProfileById = (id: number) => {
  return axios.get<PatientMedicalProfile>(`${Patient_Medical_Profile}/${id}`).then((response) => {
    return response.data
  })
}
