/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, {useState} from 'react'
//import {KTIcon} from '../../../_metronic/helpers'
import {getLayout, ILayout, useLayout} from '../../../_metronic/layout/core'
//import {LayoutSetup} from '../../../_metronic/layout/core'

const BuilderPage: React.FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('Header')
  const [config, setConfig] = useState<ILayout>(getLayout())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)
  const today = new Date().toISOString().split('T')[0];
  const calculateIMC = (taille, poids) => {
    if (!taille || !poids) return '';
    const tailleM = taille / 100;
    return (poids / (tailleM * tailleM)).toFixed(2);
  };
  const [patient, setPatient] = useState({
    nom: '',
    prenom: '',
    dateNaissance: '',
    sexe: '',
    age: '',
    ins: '',
    cardiovasculaire: {
      revascularisationCoronarienneHorsUrgences:false,
      syndromeCoronarienAigu: false,
    },
    neurovasculaire: {
      avcIschémiqueAthéromateux:false,
      aitArtéroThrombotique: false,
    },
    maladieArtériellePériphérique: {
      revascularisationArtériellePériphérique:false,
      revascularisationCarotidienne: false,
    },
    sortieHospitaliereDate: today,
    equipeSoignante: {
      medecinTraitant: '',
      endocrinoDiabetologue: '',
      vasculaireAngiologue: '',
      neurologue: '',
      cardiologue: '',
      choiSpe: ''
    },
    surveillancesNecessaires: {
      hta: false,
      htaLastMesureRef: '',
      
      ldlcObjectif: false,
      ldlcObjectifLastMesureRef: '',
      
      diabete: false,
      diabeteLastMesureRef: '',
      
      fonctionRenale: false,
      fonctionRenaleLastMesureRef: '',
      
      surchargePonderale: false,
      surchargePonderaleLastMesureRef: '',
      
      tabac: false,
      tabacLastMesureRef: '',

      pad: '',
      pas: '',
      nonHdl: false,

      ldlcValue: '',
      taille: '',

      hba1c: '',
      creatinine: '',
      poids: '',
      imc:''
    },
    objectifsTherapeutiquesPersonnalises: {
      pad: '80',
      pas: '130',
      ldlc: '0,55 g/l',
      hba1c: '7.00 %',
      creatinine: '',
      arret:'0'
    },
    traitementsMedicamenteux: {
      antithrombotiquesMultiples: false
    }
  });

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    const [parent, child] = name.split('.');
    setPatient((prevPatient) => ({
      ...prevPatient,
      surveillancesNecessaires: {
        ...prevPatient[parent],
        [child]: value,
        imc: calculateIMC(name === 'taille' ? value : prevPatient.surveillancesNecessaires.taille, name === 'poids' ? value : prevPatient.surveillancesNecessaires.poids),
      },
    }));
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setPatient(prevState => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setPatient(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to API
    console.log(patient);
  };

  const reset = () => {
    setResetLoading(true)
    setTimeout(() => {
      setConfig(getLayout())
      setResetLoading(false)
    }, 1000)
  }
  


  return (
    <>
      <style type="text/css">
              {`
          .nav-item{
            text-align: center;
          }
          .bold {
            font-weight: bold;
            }
          .m{
            margin-top:10px;
            margin-bot:10px;
            margin-right:10px;
          }
          .pb{
            padding:5px;
          }
          .msub{
            margin-top:10px;
            margin-bot:10px;
            margin-right:10px;
            font-style: italic;
          }
          `}
          
      </style>

      
      <div className='card card-custom'>
        <div className='card-header card-header-stretch overflow-auto'>
          <ul
            className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
            role='tablist'
          >
            <li className='nav-item' >
      <a
        className={clsx(`nav-link cursor-pointer`, {
          active: tab === 'Header',
        })}
        onClick={() => setTab('Header')}
        role='tab'
        style={tab === 'Header' ? { color: '#2b57a2' } : {}}
        
      >
        Patient
      </a>
    </li>

            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Evénement cardio-neurovasculaire',
                })}
                onClick={() => setTab('Evénement cardio-neurovasculaire')}
                role='tab'
                style={tab === 'Evénement cardio-neurovasculaire' ? { color: '#2b57a2' } : {}}
              >
                Evénement cardio-neurovasculaire
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Equipe soigante',
                })}
                onClick={() => setTab('Equipe soigante')}
                role='tab'
                style={tab === 'Equipe soigante' ? { color: '#2b57a2' } : {}}
              >
                Equipe soigante
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Surveillances nécessaires',
                })}
                onClick={() => setTab('Surveillances nécessaires')}
                role='tab'
                style={tab === 'Surveillances nécessaires' ? { color: '#2b57a2' } : {}}
              >
                Surveillances nécessaires
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Objectifs thérapeutiques personnalisés',
                })}
                onClick={() => setTab('Objectifs thérapeutiques personnalisés')}
                role='tab'
                style={tab === 'Objectifs thérapeutiques personnalisés' ? { color: '#2b57a2' } : {}}
              >
                Objectifs thérapeutiques personnalisés
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Traitements médicamenteux à la sortie',
                })}
                onClick={() => setTab('Traitements médicamenteux à la sortie')}
                role='tab'
                style={tab === 'Traitements médicamenteux à la sortie' ? { color: '#2b57a2' } : {}}
              >
                Traitements médicamenteux à la sortie
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={clsx(`nav-link cursor-pointer`, {
                  active: tab === 'Suivis particuliers',
                })}
                onClick={() => setTab('Suivis particuliers')}
                role='tab'
                style={tab === 'Suivis particuliers' ? { color: '#2b57a2' } : {}}
              >
                Suivis particuliers
              </a>
            </li>
          </ul>
        </div>
        {/* end::Header */}

        {/* begin::Form */}
        <form className='form'>
          {/* begin::Body */}
          <div className='card-body'>
            <div className='tab-content pt-3'>
              <div className={clsx('tab-pane', {active: tab === 'Header' })}>
                <div className='row mb-5'>
                  <div className='col-md-6'>
                  <label className='pb'>Nom :</label>
                  <div> <input className="form-control bg-transparent" type="text" name="nom" value={patient.nom} onChange={handleChange} disabled readOnly /></div>
                  </div>
                  <div className='col-md-6'>
                  <label className='pb'>Prenom :</label>
                  <input className="form-control bg-transparent" type="text" name="prenom" value={patient.prenom} onChange={handleChange} disabled readOnly />
                  </div>
                </div>
                <div className='row mb-5'>
                <div className='col-md-6'>
                  <label className='pb'>Date de naissance :</label>
                  <input className="form-control bg-transparent" type="date" name="dateNaissance" value={patient.dateNaissance} onChange={handleChange} disabled readOnly />
                </div>
                <div className='col-md-6'>
                  <label className='pb'>Sexe à la naissance : </label>
                  <input className="form-control bg-transparent" type="text" name="sexe" value={patient.sexe} onChange={handleChange} disabled readOnly />
                </div>
                </div>
                <div className='row mb-5'>
                <div className='col-md-6'>
                  <label className='pb'>Age :</label>
                  <input className="form-control bg-transparent" type="text" name="age" value={patient.age} onChange={handleChange} disabled readOnly/>
                  </div>
                  <div className='col-md-6'>
                  <label className='pb'>INS : API CNAM</label>
                  <input className="form-control bg-transparent" type="text" name="ins" value={patient.ins} onChange={handleChange} disabled readOnly />
                  </div>
                </div>
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Evénement cardio-neurovasculaire'})}>
                <div className='row mb-5'>
                  <div className='col-md-4'>
                  <label className='bold'>Cardiovasculaire :</label><br/>
                  <br/>
                  <label>
                  <input className="form-check-input-sm m" name="cardiovasculaire.revascularisationCoronarienneHorsUrgences" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.cardiovasculaire.revascularisationCoronarienneHorsUrgences ? 'true' : 'false'} onChange={handleChange}></input>Revascularisation coronarienne hors urgences</label><br/>
                  <label>
                  <input className="form-check-input-sm m" name="cardiovasculaire.syndromeCoronarienAigu" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.cardiovasculaire.syndromeCoronarienAigu ? 'true' : 'false'} onChange={handleChange}></input>Syndrome coronarien aigu</label><br/>
                  </div>
                  <div className='col-md-4' >
                  <label className='bold'>Neurovasculaire :</label><br/>
                  <br/>
                  <label><input className="form-check-input-sm m" name="neurovasculaire.avcIschémiqueAthéromateux" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.neurovasculaire.avcIschémiqueAthéromateux ? 'true' : 'false'} onChange={handleChange}></input>AVC ischémique athéromateux</label><br/>
                  <label><input className="form-check-input-sm m" name="neurovasculaire.aitArtéroThrombotique" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.neurovasculaire.aitArtéroThrombotique ? 'true' : 'false'} onChange={handleChange}></input>AIT artéro-thrombotique</label>
                  </div>
                  <div className='col-md-4'>
                  <label className='bold'>Maladie artérielle périphérique :</label><br/>
                  <br/>
                  <label><input className="form-check-input-sm m" name="maladieArtériellePériphérique.revascularisationArtériellePériphérique" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.maladieArtériellePériphérique.revascularisationArtériellePériphérique ? 'true' : 'false'} onChange={handleChange}></input>Revascularisation artérielle périphérique</label><br/>
                  <label><input className="form-check-input-sm m" name="maladieArtériellePériphérique.revascularisationCarotidienne" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.maladieArtériellePériphérique.revascularisationCarotidienne ? 'true' : 'false'} onChange={handleChange}></input>Revascularisation carotidienne</label><br/>
                  </div>
                </div>
                <div className='row mb-5'>
                  <div className='col-md-6'>
                  <label className="pb">Sortie hospitalière Date :</label>
                  <input className="form-control bg-transparent" type="text" name="sortieHospitaliereDate" value={patient.sortieHospitaliereDate} onChange={handleChange} disabled readOnly/>
                  </div>
                </div>
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Equipe soigante'})}>
              <div className='row mb-5'>
                  <div className='col-md-4'>
                  <label className='pb'>Médecin traitant :</label>
                  <div> <input  className="form-control bg-transparent" type="text" name="equipeSoignante.medecinTraitant" value={patient.equipeSoignante.medecinTraitant} onChange={handleChange} /></div>
                  </div>
                  <div className='col-md-4'>
                  <label className='pb'>Endocrino-diabétologue :</label>
                  <input className="form-control bg-transparent" type="text" name="equipeSoignante.endocrinoDiabetologue" value={patient.equipeSoignante.endocrinoDiabetologue} onChange={handleChange} />
                  </div>
                  <div className='col-md-4'>
                  <label className='pb'>Vasculaire / angeiologue :</label>
                  <input className="form-control bg-transparent" type="text" name="equipeSoignante.vasculaireAngiologue" value={patient.equipeSoignante.vasculaireAngiologue} onChange={handleChange} />
                </div>
                </div>
                <div className='row mb-5'>
                 <div className='col-md-4'>
                  <label className='pb'>Neurologue :</label>
                  <input className="form-control bg-transparent" type="text" name="equipeSoignante.neurologue" value={patient.equipeSoignante.neurologue} onChange={handleChange} />
                 </div>
                  <div className='col-md-4'>
                  <label className='pb'>Cardiologue :</label>
                  <input className="form-control bg-transparent" type="text" name="equipeSoignante.cardiologue" value={patient.equipeSoignante.cardiologue} onChange={handleChange} />
                  </div>
                  <div className='col-md-4'>
                  <label className='pb'>Choix spé :</label>
                  <input className="form-control bg-transparent" type="text" name="equipeSoignante.choiSpe" value={patient.equipeSoignante.choiSpe} onChange={handleChange} />
                  </div>
                </div>
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Surveillances nécessaires'})}>
              <div className='row mb-5'>
              <div className='col-md-4'>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.hta" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.hta ? 'true' : 'false'} onChange={handleChange} />
                            HTA
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
                <br/>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.ldlcObjectif" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.ldlcObjectif ? 'true' : 'false'} onChange={handleChange} />
                            LDLc objectif
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
                <br/>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.diabete" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.diabete ? 'true' : 'false'} onChange={handleChange} />
                            Diabète
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
                <br/>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.fonctionRenale" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.fonctionRenale ? 'true' : 'false'} onChange={handleChange} />
                            Fonction rénale
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
                <br/>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.surchargePonderale" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.surchargePonderale ? 'true' : 'false'} onChange={handleChange} />
                            Surcharge pondérale
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
                <br/>
                <div className='row'>
                        <label>
                            <input className="form-check-input-sm m" name="surveillancesNecessaires.tabac" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.tabac ? 'true' : 'false'} onChange={handleChange} />
                            Tabac
                        </label>
                        <sub className="msub">Dernière mesure de référence</sub>
                </div>
            </div>

                  <div className='col-md-4'>
                  <label><label className='pb'>PAD :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.pad" value={patient.surveillancesNecessaires.pad} onChange={handleChange} /></label><br/>
                  <br/>
                  <label><label  className='pb'>PAS :</label> <input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.pas" value={patient.surveillancesNecessaires.pas} onChange={handleChange} /></label>
                  <sub className="msub"> <input className="form-check-input-sm" name="surveillancesNecessaires.nonHdl" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.surveillancesNecessaires.nonHdl ? 'true' : 'false'} onChange={handleChange}></input>non HDL</sub><br/>
                  <br/>
                  <label><label className='pb'>LDLc :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.ldlcValue" value={patient.surveillancesNecessaires.ldlcValue} onChange={handleChange} /></label><br/>
                  <br/>
                  <label><label className='pb'>HbA1c :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.hba1c" value={patient.surveillancesNecessaires.hba1c} onChange={handleChange} /></label><sub className="msub"> %</sub><br/>
                  <br/>
                  <label><label className='pb'>Créatinine :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.creatinine" value={patient.surveillancesNecessaires.creatinine} onChange={handleChange} /></label><sub className="msub"> µmol/L</sub><br/>
                  </div>
                  <div className='col-md-4'>
                  <label><label className='pb'>Poids :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.poids" value={patient.surveillancesNecessaires.poids} onChange={handleChange2} /></label><sub className="msub"> Kg</sub><br/>
                  <br/>
                  <label><label className='pb'>Taille :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.taille" value={patient.surveillancesNecessaires.taille} onChange={handleChange2} /></label><sub className="msub"> Cm</sub><br/>
                  <br/>
                  <label><label className='pb'>IMC :</label><input className="form-control bg-transparent" type="text" name="surveillancesNecessaires.imc" value={patient.surveillancesNecessaires.imc} onChange={handleChange2} readOnly /></label><br/>
                  </div>
                </div>
              </div>

             <div className={clsx('tab-pane', {active: tab === 'Objectifs thérapeutiques personnalisés'})}>
                <div className='row mb-5'>
                      <div className='col-md-6'>
                      <label className='pb'>PAD inferieur à :</label>
                      <div> <input  className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.pad" value={patient.objectifsTherapeutiquesPersonnalises.pad} onChange={handleChange} /></div>
                      </div>
                      <div className='col-md-6'>
                      <label className='pb'>PAS inferieur à :</label>
                      <input className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.pas" value={patient.objectifsTherapeutiquesPersonnalises.pas} onChange={handleChange} />
                      </div>
          
                    </div>
                    <div className='row mb-5'>
                    <div className='col-md-12'>
                      <label className='pb'>LDLC inferieur à :</label>
                      <input className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.ldlc" value={patient.objectifsTherapeutiquesPersonnalises.ldlc} onChange={handleChange} />
                    </div>
                    </div>
                    <div className='row mb-5'>
                    <div className='col-md-2'>
                      <label className='pb bold'>Si Diabéte :</label>
                    </div>
                    <div className='col-md-10'>
                      <label className='pb'>HbA1c inferieur à :</label>
                      <input className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.hba1c" value={patient.objectifsTherapeutiquesPersonnalises.hba1c} onChange={handleChange} />
                    </div>
                    </div>
                    <div className='row mb-5'>
                    <div className='col-md-2'>
                      <label className='pb bold'>Si Insuf. rénale :</label>
                    </div>
                    <div className='col-md-10'>
                      <label className='pb'>Créatinine :</label>
                      <input className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.creatinine" value={patient.objectifsTherapeutiquesPersonnalises.creatinine} onChange={handleChange} />
                    </div>
                    </div>
                    <div className='row mb-5'>
                    <div className='col-md-2'>
                      <label className='pb bold'>Si tabac :</label>
                    </div>
                    <div className='col-md-10'>
                      <label className='pb'>Arrêt :</label>
                      <input className="form-control bg-transparent" type="text" name="objectifsTherapeutiquesPersonnalises.arret" value={patient.objectifsTherapeutiquesPersonnalises.arret} onChange={handleChange} />
                    </div>
                    </div>
                    
                {/* <div className='row mb-10'>
                  <label className='col-lg-3 col-form-label text-lg-end'>Width:</label>
                  <div className='col-lg-9 col-xl-4'>
                    <select
                      className='form-select form-select-solid'
                      name='layout-builder[layout][footer][width]'
                      value={config.footer.width}
                      onChange={(e) =>
                        updateData({
                          footer: {
                            ...config.footer,
                            width: e.target.value as 'fixed' | 'fluid',
                          },
                        })
                      }
                    >
                      <option value='fluid'>Fluid</option>
                      <option value='fixed'>Fixed</option>
                    </select>
                    <div className='form-text text-muted'>Select layout width type.</div>
                  </div>
                </div> */}
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Traitements médicamenteux à la sortie'})}>
              <div className="mb-5">
              <label><input className="form-check-input-sm m" name="traitementsMedicamenteux.antithrombotiquesMultiples" type="checkbox" data-kt-check="false" data-kt-check-target="#kt_table_users .form-check-input" value={patient.traitementsMedicamenteux.antithrombotiquesMultiples ? 'true' : 'false'} onChange={handleChange}></input>Antithrombotiques multiples</label>

                </div>
              </div>

              <div className={clsx('tab-pane', {active: tab === 'Suivis particuliers'})}>
                <label>Vérification fait / non fait sur MES. Aide à la prise de RDV + messages motivationnels</label>
              <div className="mb-5">

                </div>
              </div>

            </div>
          </div>
          {/* end::Body */}

          {/* begin::Footer */}
          <div className='card-footer py-6'>
            <div className='row'>
              <div className='col-lg-5'></div>
              <div className='col-lg-7'>
                <button type='button' onClick={handleSubmit} className='btn btn-primary me-2'>
                  {!configLoading && <span className='indicator-label'>Soumettre</span>}
                  {configLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>

                <button
                  type='button'
                  id='kt_layout_builder_reset'
                  className='btn btn-active-light btn-color-muted'
                  onClick={reset}
                >
                  {!resetLoading && <span className='indicator-label'>Réinitialiser</span>}
                  {resetLoading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
          {/* end::Footer */}
        </form>
        {/* end::Form */}
      </div>
    </>
  )
}

export {BuilderPage}
