import { FC } from "react"
import { useIntl } from "react-intl"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { BuilderPPCP } from "./BuilderPPCP"

const PCPPPage: FC = () => {

    const intl = useIntl()
  
    const accountBreadCrumbs: Array<PageLink> = [
      {
        title: intl.formatMessage({id: 'MENU.DASHBOARD'}),
        path: '/dashboard',
        isSeparator: false,
        isActive: false,
      },
    
    ]
    
    return (
      <>
        <PageTitle breadcrumbs={accountBreadCrumbs}>{intl.formatMessage({id: 'MENU.PPCP'})}</PageTitle>

        <div className='row g-5 g-xl-8'>
            <BuilderPPCP></BuilderPPCP>
        </div>
        
      </>
    )
  }
  
  export default PCPPPage