import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'

import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'




import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {DashboardPatientNonValide} from  '../pages/Patients/DashboardPatientNonValide'
import {DashboardPatientValide} from '../pages/Patients/DashboardPatientValide'
import {DashboardSuiviPatient} from '../pages/Patients/DashboardSuiviPatient'
import {DashboardWrapper4} from '../pages/Patients/DashboardWrapper4'

import BuilderPageWrapper from '../pages/Formulaire/BuilderPageWrapper'
import PCPPPage from '../pages/Formulaire/PCPPPage'



const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        
        {/* Pages */}

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='patientsnonvalide' element={<DashboardPatientNonValide />} />
        <Route path='patientsvalide' element={<DashboardPatientValide />} />

        <Route path='PPCP/:id' element={<PCPPPage />} />
        <Route path='form' element={<BuilderPageWrapper />} />
        <Route path='patientsvalide/suivis' element={<DashboardSuiviPatient />} />
        {/* <Route path='charts' element={<DashboardWrapper4 />} />
        <Route path='builder' element={<BuilderPageWrapper />} /> */}
    
     
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
